<template>
  <RRSheader />
  <page-title>
    <template v-slot:titlePrefix>Research Portal </template>
    <template v-slot:mainTitle>KPIs</template>
  </page-title>
  <div class="container mt-5 mb-5">
    <!-- doughnut chart and bar chart -->
    <div class="row my-1">
      <div class="col-4">
        <div class="border border-1 shadow rounded p-3 bg-light-gray h-100">
          <!--  doughnutChartTotals -->
          <doughnutChartTotals :destroy="destroyChart" />
        </div>
      </div>
      <div class="col-8">
        <div class="border border-1 shadow rounded p-3 bg-light-gray h-100">
          <!--  doughnutChartTotals -->
          <barChartRankingFaculties :destroy="destroyChart" />
        </div>
      </div>
    </div>
    <!-- line chart -->
    <div class="row mt-4">
      <div class="col">
        <div class="border border-1 shadow rounded p-3 bg-light-gray h-100">
          <lineChart :destroy="destroyChart" />
        </div>
      </div>
    </div>
  </div>
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "../RRSheader.vue";
import doughnutChartTotals from "@/components/statistics/doughnutChartTotals.vue";
import barChartRankingFaculties from "@/components/statistics/barChartRankingFaculties.vue";
import lineChart from "@/components/statistics/lineChart.vue";
import { hasRole } from "@/includes/helpers.js";
import store from "@/store/index.js";

export default {
  name: "kpi",
  components: {
    pageTitle,
    RRSheader,
    doughnutChartTotals,
    barChartRankingFaculties,
    lineChart,
  },
  methods: {
    destroyChart(chartInctance) {
      chartInctance.destroy();
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (!hasRole(["admin"])) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>
