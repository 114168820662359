<template>
  <!-- chart title and filter -->
  <div class="text-center">
    <h6 class="research-title">
      Research Portal line chart Performance Indicator
    </h6>
    <div class="d-flex flex-row justify-content-around">
      <!-- Input Group years filter-->
      <div class="mb-3">
        <label for="inputGroupMergeFullName" class="form-label"
          >Choos a year</label
        >

        <div class="input-group input-group-merge">
          <div
            class="input-group-prepend input-group-text"
            id="inputGroupMergeFullNameAddOn"
          >
            <i class="bi-person"></i>
          </div>
          <Datepicker
            v-model="date"
            @update:modelValue="changeChartData(date)"
            yearPicker
            :disabled="in_submission"
          ></Datepicker>
        </div>
      </div>
      <!-- End Input Group -->
      <!-- Input Group faculties filter-->
      <div class="mb-3">
        <label for="statusInput" class="form-label">Choose a faculty</label>

        <div class="input-group input-group-sm input-group-merge">
          <div class="input-group-prepend input-group-text">
            <i class="bi-person"></i>
          </div>
          <select
            id="statusInput"
            class="form-select"
            v-model="facultyFilter"
            :disabled="in_submission"
          >
            <option value="%" selected>all faculties</option>
            <option
              v-for="faculty in faculties"
              :key="faculty.id"
              :value="faculty.id"
            >
              {{ faculty.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- End Input Group -->
      <!-- Input Group status filter-->
      <div class="mb-3">
        <label for="statusInput" class="form-label">Choose a status</label>

        <div class="input-group input-group-sm input-group-merge">
          <div class="input-group-prepend input-group-text">
            <i class="bi-person"></i>
          </div>
          <select
            id="statusInput"
            class="form-select"
            v-model="statusFilter"
            :disabled="in_submission"
          >
            <option value="%" selected>In progress</option>
            <option value="Closed">Closed</option>
            <option value="Suspended">Suspended</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
      </div>
      <!-- End Input Group -->
    </div>
  </div>
  <div v-if="in_submission" class="d-flex justify-content-center text-primary">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!in_submission">
    <canvas id="lineChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapGetters } from "vuex";
import axiosConfig from "@/includes/axiosConfig";
import moment from "moment";

export default {
  name: "lineChart",
  props: {
    destroy: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters({
      faculties: "faculties",
    }),
  },
  data() {
    return {
      in_submission: false,
      labels: moment.monthsShort(),
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      myChart: null,
      date: moment().year(),
      facultyFilter: "%",
      statusFilter: "%",
    };
  },
  methods: {
    async fetchLineChartPerformance(year = moment().year()) {
      this.in_submission = true;
      let vals = {};
      vals.year = year;
      vals.facultyFilter = this.facultyFilter;
      vals.statusFilter = this.statusFilter;
      await axiosConfig
        .post(`api/lineChartPerformance`, vals)
        .then((res) => {
          console.log(res);
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    createChartInctance() {
      const ctx = document.getElementById("lineChart");
      const data = {
        labels: this.labels,
        datasets: [
          {
            label: "total researches",
            data: this.data,
            fill: false,
            borderColor: "#3182ce",
            tension: 0.1,
          },
        ],
      };
      this.myChart = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          },
        },
      });
      this.myChart;
    },
    async changeChartData(year) {
      await this.fetchLineChartPerformance(year);
      console.log(year, this.facultyFilter);
      this.destroy(this.myChart);
      this.createChartInctance();
    },
  },
  watch: {
    facultyFilter() {
      !this.in_submission ? this.changeChartData(this.date) : "";
    },
    statusFilter() {
      !this.in_submission ? this.changeChartData(this.date) : "";
    },
  },
  async created() {
    // await this.fetchLineChartPerformance();
    // if (this.faculties.length > 0) {
    //   this.labels = [];
    //   this.faculties.forEach((faculty) => {
    //     this.labels.push(faculty["name"]);
    //   });
    // }
    await this.fetchLineChartPerformance();
    this.createChartInctance();
  },
  unmounted() {
    this.destroy(this.myChart);
  },
};
</script>

<style>
.dp__select {
  color: #2670b6 !important;
}
</style>
